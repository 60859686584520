import debounce from "lodash/debounce";
import { equals, length, path } from "ramda";
import { useCallback, useContext, useMemo, useState } from "react";

import { ParameterTO } from "@encoway/c-services-js-client";

import { Switch } from "../../../components/switch/Switch";
import { SideBarContext } from "../../../context/useSidebar";
import { useTooltip } from "../../../hooks/useTooltip";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import { ConfSelect } from "./ConfSelect";

const SELECTED_VALUE_PATH = ["selectedValues", 0, "value"];

function isSelected(data: ParameterTO) {
  return equals("1", path(SELECTED_VALUE_PATH, data));
}

function TwoValueRadio({
  data,
  onValueChanged,
}: ConfiguratorComponentProps<ParameterTO>) {
  const { getTooltip } = useTooltip(data);
  const { setInfo } = useContext(SideBarContext);

  const [localSelected, setLocalSelected] = useState<boolean>(isSelected(data));
  const debouncedOnValueChanged = useCallback(
    debounce((data, value) => {
      onValueChanged(data, value);
    }, 300),
    [onValueChanged],
  );

  const currentValue = useMemo(() => {
    return localSelected ? data.values![1].value : data.values![0].value;
  }, [localSelected, data]);

  function onHover(value: string) {
    return function () {
      const tooltipInfo = getTooltip(value);
      tooltipInfo && setInfo(tooltipInfo);
    };
  }

  function handleChange() {
    const newSelected = !localSelected;
    setLocalSelected(newSelected);
    const newValue = newSelected
      ? data.values![1].value
      : data.values![0].value;
    debouncedOnValueChanged(data, newValue);
  }

  return (
    <Switch
      pre={localSelected}
      label={currentValue}
      onChange={handleChange}
      onHover={onHover(currentValue)}
    />
  );
}

export function ConfRadio(props: ConfiguratorComponentProps<ParameterTO>) {
  if (equals(length(props.data.values!), 2)) {
    return <TwoValueRadio {...props} />;
  }
  return <ConfSelect {...props} />;
}
